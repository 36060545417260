<template>
    <div class="index">
        <div class="index-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <a class="login" :href="base.adminLink">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/manager.png)"></div>
                </div>
                管理员登录
            </a>
        </div>
        <div class="index-center">
            <div class="main-item" @click="testOpenPopup">
                <div class="txt">
                    <div class="t1">量表测评</div>
                    <div class="t2">专家指导，保障信效度和专业性</div>
                </div>
                <div class="icon">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/index_icon0.png)"></div>
                    </div>
                </div>
            </div>
            <div class="itemlist">
                <div class="item" style="background-color: #FF743E;"
                    @click="goto('/musicplayer', { id: musics[0].id })">
                    <div class="txt">
                        <div class="t1">心理调适</div>
                        <div class="t2">放松好心情</div>
                    </div>
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/index_icon1.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="item" style="background-color: #3E99FF;" @click="goto('/servicecenter')">
                    <div class="txt">
                        <div class="t1">心理咨询服务中心</div>
                        <div class="t2">心理咨询 打开心结</div>
                    </div>
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/index_icon2.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="item" style="background-color: #43CF8D;" @click="goto('/appointment')">
                    <div class="txt">
                        <div class="t1">专家与预约指引</div>
                        <div class="t2">预约指导 方便快捷</div>
                    </div>
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/index_icon3.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="item" style="background-color: #903EFF;" @click="goto('/mentality')">
                    <div class="txt">
                        <div class="t1">心理科普</div>
                        <div class="t2">放松好心情</div>
                    </div>
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/index_icon4.png)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="index-bottom">
            <div class="double">
                <div class="itemlist">
                    <div class="item">
                        <div class="titlebox"
                            @click="goto('/mentality/mangalist', { cateName: '科普漫画', openKey: 1 })">
                            <div class="atitle">科普漫画</div>
                            <div class="btitle">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/more.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="qalist">
                            <div class="qa-item" v-for="(item, index) in manhua" :key="index">
                                <div class="txt"
                                    @click="goto('/compositionShow', { id: item.id, cateName: '科普漫画' })">{{
                                            item.title
                                    }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="titlebox" @click="goto('/musicplayer', { id: musics[0].id })">
                            <div class="atitle">十分钟冥想</div>
                            <div class="btitle">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/more.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="musiclist">
                            <div class="music" v-for="(item, index) in musics" :key="index"
                                @click="goto('/musicplayer', { id: item.id })">
                                <div class="play">
                                    <div class="picbox">
                                        <div class="pic" style="background-image: url(/img/music_play.png)"></div>
                                    </div>
                                </div>
                                <div class="music-title">
                                    <div class="atitle"><span class="title">{{ item.title }}</span><span class="tag"
                                            v-if="item.categoryTitle">{{ item.categoryTitle }}</span></div>
                                    <div class="btitle">{{ item.author }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single">
                <div class="item">
                    <div class="titlebox" @click="goto('/about')">
                        <div class="atitle">普陀悦心亭</div>
                        <div class="btitle">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/more.png)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce" @click="goto('/about')">{{ base.webDesc }}</div>
                    <button class="btn" @click="connectWebrtc()">
                        <div class="icon">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/tel.png)"></div>
                            </div>
                        </div>
                        <div class="txt">心理咨询和预约热线：{{ base.webTel }}</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telShow }">
            <div class="shadow" @click="telCancel(1)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">你好，请问是否需要进行电话心理咨询和预约？</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telCancel(1)">取消拨号</button>
                    <button class="btn" @click="ringUp">确认拨号</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telConnected }">
            <div class="shadow" @click="telCancel(2)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">该服务点暂未开通此服务</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telCancel(2)">取消</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telRinging }">
            <div class="shadow" @click="telCancel(3)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">拨号中。。。</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telPopup()">挂断</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telActive }">
            <div class="shadow" @click="telCancel(4)"></div>
            <div class="tel-keyboard">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1" >
                        {{ keyboardTxt }}
                    </div>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('1')">1</button>
                    <button class="keyboard" @click="keyboardDown('2')">2</button>
                    <button class="keyboard" @click="keyboardDown('3')">3</button>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('4')">4</button>
                    <button class="keyboard" @click="keyboardDown('5')">5</button>
                    <button class="keyboard" @click="keyboardDown('6')">6</button>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('7')">7</button>
                    <button class="keyboard" @click="keyboardDown('8')">8</button>
                    <button class="keyboard" @click="keyboardDown('9')">9</button>
                </div>
                <div class="btns">
                    <button class="keyboard" @click="keyboardDown('*')">*</button>
                    <button class="keyboard" @click="keyboardDown('0')">0</button>
                    <button class="keyboard" @click="keyboardDown('#')">#</button>
                </div>
                <div class="btns">
                    <button class="btn" @click="telPopup()">挂断</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: telHangup }">
            <div class="shadow" @click="telCancel(5)"></div>
            <div class="tel-popup">
                <div class="img">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/popup_tel.png)"></div>
                    </div>
                </div>
                <div class="txt">
                    <div class="t1">通话已结束</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="telCancel(5)">退出</button>
                </div>
            </div>
        </div>
        <div class="popup" :class="{ active: testShow }">
            <div class="shadow" @click="testClosePopup"></div>
            <div class="test-popup">
                <div class="tishi">
                    <div class="txt">
                        <div class="t1">请选择您要测评的类型</div>
                        <div class="t2">请从以下测评的分类中，选择一个您适用的量表，然后点击“下一步”填写您的基本信息，进行测评</div>
                    </div>
                    <button class="btn" @click="createTestLink()">下一步</button>
                </div>
                <div class="cardlist">
                    <div class="card" :style="{ background: item.bgColor }" v-for="(item, index) in popupCard"
                        :key="index">
                        <div class="card-main">
                            <div class="titlebox">
                                <div class="img">
                                    <div class="picbox">
                                        <div class="pic" :style="{ backgroundImage: 'url(' + item.icon + ')' }"></div>
                                    </div>
                                </div>
                                <div class="title">
                                    <div class="atitle">{{ item.atitle }}</div>
                                    <div class="btitle">{{ item.btitle }}</div>
                                </div>
                            </div>
                            <div class="input">
                                <div class="radio" v-for="(val, k) in item.radios" :key="k">
                                    <input :id="'radio' + index + k" type="radio" :value="val.value" v-model="value" />
                                    <label :for="'radio' + index + k">{{ val.key }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="bg">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/popup_cardbg.png)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <video width=800 id="webcam" autoplay="autoplay" hidden="true"></video>
    </div>
</template>

<script>
import { getBase, getUserInfo, getWebrtcParams, getInfo } from "@/js/request";
import { getMangaList2 } from "@/js/request";
import { getMusicList } from "@/js/request";
import { ElMessage } from 'element-plus'
import { loginVerto, doCall, decline, logout, keyboardClick } from '../js/verto.js'



// @ is an alias to /src
export default {
    name: 'Index',
    components: {
    },
    data() {
        return {
            base: {},
            manhua: [],
            musics: [],
            telShow: false,
            telConnected: false,
            telRinging: false,
            telActive: false,
            telHangup: false,
            testShow: false,
            popupCard: [
                {
                    bgColor: 'linear-gradient(0deg, #FF743E 0%, #FF8E62 100%)', icon: '/img/popup_icon1.png', atitle: '儿童及青少年', btitle: '测评', value: '',
                    radios: [
                        {key:'儿童社交焦虑量表',value:'YXT_SASC'}, {key:'艾森克人格问卷（少年版）',value:'YXT_EPQC'}, {key:'考试焦虑量表',value:'YXT_TAS'}, {key:'儿童青少年压力量表',value:'YXT_CASS'},
                        {key:'Rutter儿童行为问卷（父母）',value:'YXT_RCBSP'},
                        {key:'中国中学生心理健康量表',value:'YXT_MSSMHS'},
                        {key:'儿童抑郁量表',value:'YXT_CDI'},
                        {key:'儿童焦虑性情绪障碍筛查量表',value:'YXT_SCARED'},
                        {key:'Cleveland青少年嗜睡问卷',value:'YXT_CASQ'},
                        {key:'认知情绪调节量表',value:'YXT_CERQ'},
                        {key:'青少年学习倦怠量表',value:'YXT_ASBI'},
                        {key:'学习方法测验',value:'YXT_TML'},
                        {key:'学习时间管理',value:'YXT_LTM'},
                        {key:'中学生学习动机量表',value:'YXT_MSMT'},
                        {key:'中学生在校适应能力测试',value:'YXT_SYNL'},
                        {key:'中学生考试心理和行为问题症状自评量表',value:'YXT_EMP'},
                        {key:'中学生自我导向学习倾向性量表',value:'YXT_SDLRS'},
                        {key:'青少年手机使用依赖自评问卷',value:'YXT_SQAPMPU'},
                        {key:'青少年上网成瘾自评量表',value:'YXT_AQAP'},
                    ]
                },
                {
                    bgColor: 'linear-gradient(0deg, #3F65E0 0%, #6081EA 100%)', icon: '/img/popup_icon2.png', atitle: '成年人', btitle: '心理测评', value: '',
                    radios: [{key:'宗(Zung)氏抑郁自评量表',value:'YXT_SDS'}, {key:'宗(Zung)氏焦虑自评量表',value:'YXT_SAS'}, {key:'蒙哥马利抑郁评定量表',value:'YXT_MADRS'}, {key:'患者健康问卷焦虑分量表',value:'YXT_GAD-7'}, {key:'患者健康问卷抑郁筛查',value:'YXT_PHQ-9'}, {key:'Locke-Wallace婚姻调适评定',value:'YXT_LWM'}, {key:'压力知觉量表',value:'YXT_CPSS'}, {key:'大五人格问卷简版',value:'YXT_NEO'}, {key:'艾森克人格问卷简式量表',value:'YXT_EPQ-RSC'}]
                },
                {
                    bgColor: 'linear-gradient(0deg, #43CF8D 0%, #5DD99E 100%)', icon: '/img/popup_icon3.png', atitle: '老年人', btitle: '心理测评', value: '',
                    radios: [{key:'老年抑郁量表',value:'YXT_GDS'}, {key:'UCLA孤独量表',value:'YXT_UCLAO'}, {key:'匹兹堡睡眠质量指数',value:'YXT_PSQI'}, {key:'心境障碍问卷',value:'YXT_MDQ'}]
                }
            ],
            value: '',
            vertoExto: 0,
            vertoAgentPwd: '', 
            vertoSerIp: '', 
            vertoSerPort: 0,
            callPhoneNumber: '',
            keyboardTxt: ''
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({ path: adress, query: cate });
        },
        telCancel(cancelType) {
            if(cancelType == 1){
                this.telShow = false;
            }else if(cancelType == 2){
                this.telConnected = false;
            }else if(cancelType == 3){
                this.telRinging = false;
            }else if(cancelType == 4){
                this.telActive = false;
            }else if(cancelType == 5){
                this.telHangup = false;
            }
            logout();
        },
        connectWebrtc() {
            const webrtcId = window.localStorage.getItem('webrtcId');
            let webrtcIndex = window.localStorage.getItem('webrtcIndex');
            // 增加点击事件
            getInfo(webrtcId).then(res => {
                console.log("res=", res)
            });
            if(webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == ""){
                this.telShow = false;
                this.telConnected = true;
                return;
            }
            console.log("webrtcIndex2", webrtcIndex);
            webrtcIndex = webrtcIndex.split("?")[0];
            console.log("webrtcIndex3", webrtcIndex);
            
            getWebrtcParams(webrtcId).then(res => {
                const webrtcParams = res.data[webrtcIndex];
                if(webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null){
                    this.telConnected = true;
                    return;
                }
                this.vertoExto = webrtcParams.articleFields[0].fieldValue;
                this.vertoAgentPwd = webrtcParams.articleFields[1].fieldValue;
                this.vertoSerIp = webrtcParams.articleFields[2].fieldValue;
                this.vertoSerPort = webrtcParams.articleFields[3].fieldValue;
                this.callPhoneNumber = webrtcParams.articleFields[4].fieldValue;
                loginVerto(this.vertoExto, this.vertoAgentPwd, this.vertoSerIp, this.vertoSerPort, 
                    ()=>{
                        logout();
                        this.telShow = true;
                        this.telHangup = false;
                        this.telConnected = false;
                        this.telRinging = false;
                    }, 
                    ()=>{
                        logout();
                        this.telHangup = true;
                        this.telShow = false;
                        this.telConnected = false;
                        this.telRinging = false;
                    }
                );
            });
        },
        ringUp() {
            doCall(this.vertoExto, this.callPhoneNumber,
                ()=>{
                    this.telRinging = true;
                    this.telActive = false;
                    this.telHangup = false;
                    this.telShow = false;
                    this.telConnected = false;
                },
                ()=>{
                    this.telActive = true;
                    this.telRinging = false;
                    this.telHangup = false;
                    this.telShow = false;
                    this.telConnected = false;
                });
        },
        telPopup(){
            this.keyboardTxt = "";
            decline();
            logout();
            this.telHangup = true;
            this.telActive = false;
            this.telShow = false;
            this.telConnected = false;
            this.telRinging = false;
        },
        testOpenPopup() {
            this.testShow = true;
        },
        testClosePopup() {
            this.testShow = false;
        },
        keyboardDown(keyboardNum) {
            this.keyboardTxt += keyboardNum;
            keyboardClick(keyboardNum);
        },
        createTestLink() {
            // console.log("this.value",this.value)
            if(this.value==''){
                ElMessage({
                    message: "请选择您要测评的项目",
                    type: 'warning',
                })
                return;
            }
            getUserInfo(this.value).then(res => {
                const webrtcId = window.localStorage.getItem('webrtcId');
                let webrtcIndex = window.localStorage.getItem('webrtcIndex');
                if(webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                    webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == ""){
                    const depId = 3;
                    const jumpJrl = res.data.url + "&depId=" + depId;
                    console.log("jumpJrl=", jumpJrl)
                    window.location.href = jumpJrl;
                }else{
                    webrtcIndex = webrtcIndex.split("?")[0];
                    
                    getWebrtcParams(webrtcId).then(WebrtcRes => {
                        const webrtcParams = WebrtcRes.data[webrtcIndex];
                        if(webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null){
                            this.telConnected = true;
                            return;
                        }
                        const depId = webrtcParams.articleFields[5].fieldValue;
                        const jumpJrl = res.data.url + "&depId=" + depId;
                        console.log("jumpJrl=", jumpJrl)
                        window.location.href = jumpJrl;
                    });
                }
            }).catch(res => {
                if (res.response.status == 401) {   
                    window.localStorage.setItem('modelKey', this.value);                 
                    this.$router.push('/home?value='+encodeURIComponent(this.value));
                } else {
                    alert(res.response.statusText);
                }
            });
            // 每次登录都需要输入新账号
            // this.$router.push('/home?value='+encodeURIComponent(this.value));
        }
    },
    mounted() {
        this.$root.showRight();
        if (this.$route.query.wen == 1) {
            this.testShow = true;
        }
        getBase().then(res => {
            this.base = res.data;
        });
        getMangaList2().then(res => {
            this.manhua = res.data;
        });
        getMusicList().then(res => {
            this.musics = res.data;
        });
    }
    

}
</script>

<style lang="scss">
@media (max-height: 1000px) {
    .index {
        padding: 40px 0 60px 0 !important;
    }

    .index-top {
        margin-bottom: 40px !important;
    }
}

.index {
    width: 1400px;
    margin: 0 auto;
    padding: 60px 0;
    box-sizing: border-box;
    max-height: 100vh;

    .index-top {
        @include sys-flex(space-between, row);
        margin-bottom: 80px;

        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }

        .login {
            @include sys-flex(center, row);
            @include sys-boxsize(240px, 60px, 10px, #F6F8FF);
            @include sys-font(20px, $acolor);
            @include sys-picbox(17px, 20px);
            cursor: pointer;

            .picbox {
                margin-right: 10px;
            }
        }
    }

    .index-center {
        display: flex;
        margin-bottom: 40px;

        .main-item,
        .itemlist .item {
            cursor: pointer;

            .icon {
                transition: transform .8s;
            }
        }

        .main-item {
            flex: none;
            width: 440px;
            height: 330px;
            box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
            border-radius: 20px;
            background: url(../assets/img/index_mainbg.png) no-repeat center center/contain;
            background-color: #3F65E0;
            padding: 40px 50px;
            box-sizing: border-box;
            text-align: left;
            position: relative;

            &:hover {
                .icon {
                    transform: scale(1.1);
                }
            }

            .txt {
                width: 220px;
                position: relative;
                z-index: 5;

                .t1 {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #fff, bold);
                    line-height: 38px;
                    margin-bottom: 20px;
                }

                .t2 {
                    @include sys-text-hide(3);
                    @include sys-font(20px, #fff);
                }
            }

            .icon {
                position: absolute;
                bottom: 44px;
                right: 50px;
                @include sys-picbox(130px, 130px);
            }
        }

        .itemlist {
            @include sys-flex(space-between, row);
            flex-wrap: wrap;
            flex: 1;
            height: 330px;
            margin-left: 40px;

            .item {
                display: flex;
                align-items: center;
                width: 440px;
                height: 150px;
                box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
                border-radius: 20px;
                background: url(../assets/img/index_itembg.png) no-repeat center bottom/contain;
                margin-bottom: 30px;
                padding: 20px 50px;
                box-sizing: border-box;
                text-align: left;
                position: relative;

                &:hover {
                    .icon {
                        transform: translateY(50%) scale(1.1);
                    }
                }

                .txt {
                    width: 100%;
                    position: relative;
                    z-index: 5;

                    .t1 {
                        @include sys-text-hide(1);
                        @include sys-font(36px, #fff, bold);
                        line-height: 46px;
                    }

                    .t2 {
                        @include sys-text-hide(2);
                        @include sys-font(20px, #fff);
                        line-height: 30px;
                    }
                }

                .icon {
                    position: absolute;
                    bottom: 50%;
                    right: 54px;
                    transform: translateY(50%);
                    @include sys-picbox(90px, 90px);
                }
            }
        }
    }

    .index-bottom {
        @include sys-flex(space-between, row);
        width: 100%;
        height: 330px;

        .double,
        .single {
            height: 100%;
            background: #fff;
            border-radius: 20px;
            box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
            padding: 40px 50px;
            box-sizing: border-box;
            text-align: left;

            .titlebox {
                @include sys-flex(space-between, row);
                cursor: pointer;
                .atitle {
                    flex: 1;
                    @include sys-text-hide(1);
                    @include sys-font(28px, #000, bold);
                    line-height: 30px;
                }

                .btitle {
                    flex: none;
                    padding: 0 10px;
                    cursor: pointer;
                    @include sys-picbox(22px, 16px);
                }
            }
        }

        .double {
            width: 920px;

            .itemlist {
                display: flex;
                width: calc(100% + 90px);
                height: 100%;
                overflow: hidden;
                margin: 0 -45px;

                .item {
                    width: 50%;
                    padding: 0 45px;

                    .qalist {
                        margin-top: 30px;

                        .qa-item {
                            @include sys-text-hide(1);
                            @include sys-font(16px, #000);
                            line-height: 40px;
                            cursor: pointer;
                        }
                    }

                    .musiclist {
                        margin-top: 40px;

                        .music {
                            @include sys-boxsize(100%, 80px, 10px, #F6F8FF);
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;
                            padding: 10px 30px;
                            box-sizing: border-box;
                            cursor: pointer;

                            .play {
                                @include sys-picbox(42px, 42px);
                                margin-right: 20px;
                            }

                            .music-title {
                                .atitle {
                                    display: flex;
                                    line-height: 24px;

                                    .title {
                                        @include sys-text-hide(1);
                                        @include sys-font(20px, #000);
                                    }

                                    .tag {
                                        @include sys-text-hide(1);
                                        @include sys-font(14px, #fff);
                                        background-color: $acolor;
                                        border-radius: 4px;
                                        flex: none;
                                        margin-left: 10px;
                                        padding: 0 6px;
                                    }
                                }

                                .btitle {
                                    @include sys-text-hide(1);
                                    @include sys-font(14px, #999);
                                }
                            }
                        }
                    }
                }
            }
        }

        .single {
            width: 440px;

            .item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                overflow: hidden;

                .introduce {
                    @include sys-text-hide(5);
                    @include sys-font(16px, #000);
                }

                .btn {
                    @include sys-boxsize(100%, 70px, 10px, $acolor);
                    @include sys-flex(center, row);
                    @include sys-picbox(22px, 22px);
                    cursor: pointer;

                    .icon {
                        flex: none;
                    }

                    .txt {
                        margin-left: 10px;
                        @include sys-text-hide(1);
                        @include sys-font(16px, #fff);
                    }
                }
            }
        }
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        .shadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .8);
        }

        .tel-popup {
            @include sys-flex();
            width: 450px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img {
                @include sys-picbox(192px, 193px);
                margin-bottom: -110px;
                position: relative;
                z-index: 5;
            }

            .txt {
                @include sys-font(24px, #000);
                line-height: 34px;
                width: 100%;
                padding: 106px 60px 34px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 20px 20px 0 0;
            }

            .btns {
                width: 100%;
                height: 80px;
                display: flex;

                .btn {
                    @include sys-flex();
                    flex: 1;
                    height: 100%;
                    font-size: 24px;
                    cursor: pointer;

                    &:first-child {
                        background: #F6F6F6;
                        border-bottom-left-radius: 20px;
                        color: #666;
                    }

                    &:last-child {
                        background: $acolor;
                        border-bottom-right-radius: 20px;
                        color: #fff;
                    }
                }
            }
        }
        .tel-keyboard {
            @include sys-flex();
            width: 450px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img {
                @include sys-picbox(192px, 193px);
                margin-bottom: -110px;
                position: relative;
                z-index: 5;
            }

            .txt {
                @include sys-font(24px, #000);
                line-height: 34px;
                width: 100%;
                padding: 106px 60px 34px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 20px 20px 0 0;
            }

            .btns {
                width: 100%;
                height: 80px;
                display: flex;

                .keyboard {
                    @include sys-flex();
                    flex: 1;
                    height: 100%;
                    font-size: 24px;
                    cursor: pointer;
                    border: 2px solid #fff;

                    background: #F6F6F6;
                    color: #666;
                }

                .btn {
                    @include sys-flex();
                    flex: 1;
                    height: 100%;
                    font-size: 24px;
                    cursor: pointer;

                    &:first-child {
                        background: #F6F6F6;
                        border-bottom-left-radius: 20px;
                        color: #666;
                    }

                    &:last-child {
                        background: $acolor;
                        border-bottom-right-radius: 20px;
                        color: #fff;
                    }
                }
            }
        }

        .test-popup {
            width: 1400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .tishi {
                @include sys-flex(space-between, row);
                margin-bottom: 60px;

                .txt {
                    max-width: 900px;

                    .t1 {
                        @include sys-text-hide(1);
                        @include sys-font(42px, #F6F8FF, bold);
                        margin-bottom: 20px;
                    }

                    .t2 {
                        @include sys-text-hide(2);
                        @include sys-font(28px, #F6F8FF);
                    }
                }

                .btn {
                    @include sys-radius-btn(180px, 60px, transparent);
                    border: 2px solid #fff;
                    font-size: 24px;
                    font-weight: bold;
                }
            }

            .cardlist {
                @include sys-flex(space-between, row);

                .card {
                    @include sys-boxsize(440px, 540px, 20px);
                    padding: 50px 30px 50px 80px;
                    box-sizing: border-box;
                    overflow: hidden;
                    position: relative;

                    .card-main {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        z-index: 5;

                        .titlebox {
                            display: flex;
                            align-items: center;
                            margin-bottom: 25px;
                            flex: none;

                            .img {
                                width: 80px;
                                height: 80px;
                                @include sys-picbox(100%, 100%);
                                flex: none;
                            }

                            .title {
                                @include sys-font(28px, #fff);
                                margin-left: 30px;
                                flex: 1;

                                .atitle,
                                .btitle {
                                    @include sys-text-hide(1);
                                }

                                .atitle {
                                    font-weight: bold;
                                }
                            }
                        }

                        .input {
                            width: 100%;
                            flex: 1;
                            overflow: hidden;

                            &::-webkit-scrollbar {
                                width: 3px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #fff;
                                border-radius: 2px;
                            }

                            &::-webkit-scrollbar-resizer {
                                background: $acolor;
                            }

                            &::-webkit-scrollbar-track-piece {
                                background: $acolor;
                                border-radius: 2px;
                            }

                            .radio {
                                width: 100%;
                                height: 50px;
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                @include sys-font(20px, #F6F8FF);

                                label {
                                    // @include sys-text-hide(1);
                                    overflow: visible;
                                    position: relative;
                                    cursor: pointer;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        left: -40px;
                                        @include sys-radius-btn(20px, 20px, rgba(0, 0, 0, .15));
                                        transform: translateY(-50%);
                                        box-sizing: border-box;
                                    }
                                }

                                input:checked+label::before {
                                    background: url(../assets/img/popup_radio.png) no-repeat center center/contain;
                                    border: none;
                                }

                                input {
                                    @include sys-radius-btn(20px, 20px, #F8FAFE);
                                    border: 2px solid #BCC3D8;
                                    margin-right: 20px;
                                    opacity: 0;
                                }
                            }
                        }
                    }

                    .bg {
                        position: absolute;
                        bottom: 0;
                        right: 4px;
                        width: 235px;
                        height: 160px;
                        @include sys-picbox(100%, 100%);
                    }
                }
                .card:hover {
                    .card-main{
                        .input{
                            overflow: auto;
                        }
                    }
                }
            }
        }
    }
}
</style>